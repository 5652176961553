.App {
  text-align: center;
}

/* .App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
} */

/* Smooth animation for plane */
@keyframes move-plane {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(50%);
  }
}

.animate-move-plane {
  animation: move-plane 2s ease-in-out forwards;
}
